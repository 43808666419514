
import { Component, Mixins, Vue } from 'vue-property-decorator';
import AMapLoader from '@amap/amap-jsapi-loader';
import { request as axios } from '@/util/request';
import moment from 'moment';

@Component
export default class RiderManagement extends Vue{
    map: any
    geocoder=null; // 地理编码与逆地理编码类，用于地址描述与坐标之间的转换
    infoWindow: any; // 用于在地图上弹出一个详细信息展示窗体
    showInfo=false; // 控制信息窗体的组件隐藏
    jwd: string[]=[];//经纬度
    showfj: boolean=false;//附近骑手弹窗
    infoObj={  // 传递给信息弹窗的数据
    title:"项目介绍",
      contentList:[{code:"编号：SSSSSSDEJUW",num:"创航向：126°"},{code:"编号：SSSSSSDEJUW",num:"创航向：126°"}]
    };
    tanData: any;//骑手弹窗内容
    fjData: any
    fjtc= [
      {
        title:'骑手姓名：',
        body:'',
        dis:true
      },
      {
        title:'联系电话：',
        body:'',
        dis:true
      },
      {
        title:'今日送单量：',
        body:0,
        dis:true
      },
      {
        title:'累计送单量：',
        body:0,
        dis:true
      },
      {
        title:'骑手位置：',
        body:'',
        dis:true
      },
      {
        title:'',
        body:'',
        dis:false
      }
    ]
    knightinf=[
      {
        name:'配送状态：',
        body:'',
        dis:true
      },
      {
        name:'预计送达时间：',
        body:'',
        dis:true
      },
      {
        name:'取货码：',
        body:'',
        dis:true
      },
      {
        name:'骑手姓名：',
        body:'',
        dis:true
      },
      {
        name:'联系电话：',
        body:'',
        dis:true
      },
      {
        name:'佣金（元）',
        body:0,
        dis:true
      },
      {
        name:'抢单时间：',
        body:'',
        dis:true
      },
      {
        name:'取货时间：',
        body:'',
        dis:false
      },
      {
        name:'骑手位置：',
        body:'',
        dis:true
      },
    ]
    async getData(){
      if(this.$route.query.type=='info'){
        const res=await axios.get(`/boss/knight/order/${this.$route.query.id}`)
        this.tanData=res.data
        this.knightinf[0].body=this.tanData.status==4?'待取货':'待送达'
        this.knightinf[1].body=moment(this.tanData.delivery_time*1000).format('YYYY-MM-DD')
        this.knightinf[2].body=this.tanData.pickup_code
        this.knightinf[3].body=this.tanData.knight_name
        this.knightinf[4].body=this.tanData.knight_mobile
        this.knightinf[5].body=this.tanData.knight_commission/100
        this.knightinf[6].body=moment(this.tanData.grab_time*1000).format('YYYY-MM-DD')
        this.knightinf[7].body=moment(this.tanData.pickup_time*1000).format('YYYY-MM-DD')
        this.knightinf[8].body=this.tanData.knight.address
        this.tanData.status==5?this.knightinf[7].dis=true:false
        this.jwd=[]
        this.jwd.push(res.data.knight.lon)
        this.jwd.push(res.data.knight.lat)
      }else{
        const res=await axios.get(`/boss/knight/order/nearbyknight`,{
          params:{
            id:this.$route.query.id
          }
        })
        this.fjData=res.data
        console.log(res)
      }
      
    }
    async created(){
      window.setInterval(()=>{
        setTimeout(async ()=>{
          await this.getData()
        },0)
      },60000)
      await this.getData()
      this.initMap()
    }
    get logo() {
    return require('@/asset/img/buy.png');
    }
    //指派
    async sign(){
      const r=confirm(`确定要把订单编号${this.$route.query.order_number}的订单指派给${this.fjtc[0].body}吗？`)
      if(r){
        const body={
          id:this.$route.query.id,
          knight_id:this.fjtc[5].body
        }
        const res=await axios.post(`/boss/knight/order/sign`,body)
        if(res.status==200){
          this.$message.success('指派成功')
        }else{
          this.$message.error((res as any).message)
        }
      }
    }
    //地图
    marker: any
    oldmarker: any
    oldmarkes: any
    initMap(){
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        AMapLoader.load({
            "key": "44c3cdd1674c99a6e1a440f495c1367f", // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ["AMap.Scale", "AMap.Geocoder", " AMap.InfoWindow", "AMap.ToolBar",'AMap.SimpleInfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            "AMapUI": { // 是否加载 AMapUI，缺省不加载
            "version": '1.1', // AMapUI 缺省 1.1
            "plugins": [], // 需要加载的 AMapUI ui插件
             },
            "Loca": { // 是否加载 Loca， 缺省不加载
            "version": '2.0' // Loca 版本，缺省 1.3.2
            },
        }).then((AMap) => {
          this.map = new AMap.Map('container',{
              center:this.$route.query.type=='info'? this.jwd:[117.928025,36.850767],
              zoom: 15,
              viewMode: '3D',
          });
          //给地图新增缩放条，和 地图左下角的海里控件
          this.map.addControl(new AMap.Scale())
          this.map.addControl(new AMap.ToolBar())
          //  that.map.setMapStyle('amap://styles/blue');  控制地图主题的api，darkblue是极夜蓝，下面会贴出我的地图效果
            //this.map.setMapStyle('amap://styles/darkblue');
            console.log('055')
                if(this.$route.query.type=='info'){
              this.marker = new AMap.Marker({
              position: new AMap.LngLat(...this.jwd),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: '纽澜地',
              icon:`${this.logo}`,
              //extData:{title:'我是帅哥',content:"6666666666"}
              });
              this.infoWindow =  new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体，则默认的样式都没
              offset: new AMap.Pixel(0, -35),//控制弹窗的偏移量
              content: this.$refs.infoData,
              })
              this.map.add(this.marker)
              this.marker.on("click", this.markerClick)
            }else{
              this.fjData.forEach((element: any) => {
                const marker = new AMap.Marker({
                position: [element.lon,element.lat],   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: '纽澜地',
                icon:`${this.logo}`,
                extData:{
                  name:element.name,
                  mobile:element.mobile,
                  income_today:element.ext==null?0:element.ext.income_today,
                  delivered_count_today:element.ext==null?0:element.ext.delivered_count_today,
                  address:element.address,
                  id:element.id,
                  }
              });
              this.map.add(marker)
              this.infoWindow =  new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体，则默认的样式都没
              offset: new AMap.Pixel(0, -35),//控制弹窗的偏移量
              content: this.$refs.fjData,
              })
              marker.on("click", this.markerClick)
              });
            }
            window.setInterval(()=>{
              window.setTimeout(()=>{
                console.log('055')
                this.map.clearMap(this.oldmarker)
                if(this.$route.query.type=='info'){
              const marker = new AMap.Marker({
              position: new AMap.LngLat(...this.jwd),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: '纽澜地',
              icon:`${this.logo}`,
              //extData:{title:'我是帅哥',content:"6666666666"}
              });
              this.infoWindow =  new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体，则默认的样式都没
              offset: new AMap.Pixel(0, -35),//控制弹窗的偏移量
              content: this.$refs.infoData,
              })
              this.oldmarker=marker
              this.map.add(marker)
              marker.on("click", this.markerClick)
            }else{
              this.map.clearMap(this.oldmarkes)
              this.fjData.forEach((element: any) => {
                const marker = new AMap.Marker({
                position: [element.lon,element.lat],   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: '纽澜地',
                icon:`${this.logo}`,
                extData:{
                  name:element.name,
                  mobile:element.mobile,
                  income_today:element.ext==null?0:element.ext.income_today,
                  delivered_count_today:element.ext==null?0:element.ext.delivered_count_today,
                  address:element.address,
                  id:element.id,
                  }
              });
              this.oldmarkes=marker
              this.map.add(marker)
              this.infoWindow =  new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体，则默认的样式都没
              offset: new AMap.Pixel(0, -35),//控制弹窗的偏移量
              content: this.$refs.fjData,
              })
              marker.on("click", this.markerClick)
              });
            }
              },0)
            },60000)
            
            
	}).catch(e => {
	console.log(e);
	});
    }
    //图标点击事件
    markerClick(e: any) {
      console.log(e)
      console.log(this.infoWindow)
      if(this.$route.query.type=='info'){
        this.showInfo = true;
        this.infoWindow.setContent(this.$refs.infoData)//这个是获取整个弹窗的组件dom，绘画到infoWindow控件里面，setContent可识别html字符串
        this.infoWindow.open(this.map,e.lnglat);//控制infoWindow控件，打开，传递经纬度
        this.showInfo = true;
      }else{
        this.fjtc[0].body=e.target._originOpts.extData.name
        this.fjtc[1].body=e.target._originOpts.extData.mobile
        this.fjtc[2].body=e.target._originOpts.extData.income_today
        this.fjtc[3].body=e.target._originOpts.extData.delivered_count_today
        this.fjtc[4].body=e.target._originOpts.extData.address
        this.fjtc[5].body=e.target._originOpts.extData.id
        this.showfj=true
        this.infoWindow.setContent(this.$refs.fjData)//这个是获取整个弹窗的组件dom，绘画到infoWindow控件里面，setContent可识别html字符串
        this.infoWindow.open(this.map,e.lnglat);//控制infoWindow控件，打开，传递经纬度
      }
      
      //const obj = e.target.getExtData();//获取maker的自定义数据
      //this.infoObj.title = obj.title // infoObj是传递给信息弹窗组件的数据
      //this.map.setCenter(e.target.getPosition()) // 这个是设置地图的中心点视野
      //this.map.setZoom(15)
    }
    //关闭弹窗
    close(){
      this.showInfo=false
    }

}
